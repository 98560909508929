import React from "react";

import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
    Card,
    CardBody,
    Typography,
} from "@material-tailwind/react";
import {
    PaintBrushIcon,
    CodeBracketIcon,
} from "@heroicons/react/24/solid";

import fj from "./images/fj.png";
import hepsi from "./images/hepsi.png";
import nawa from "./images/nawa.png";
import sima from "./images/sima.png";
import { useTranslation } from "react-i18next";


export function ProgrammingTabs() {
    const [t] = useTranslation("global");

    const Programing = () => {
        const data = [
            {
                title: "fjmimarlik",
                description: "fj",
                img: fj
            },
            {
                title: "hepsiistanbul",
                description: "hepsi",
                img: hepsi
            },
            {
                title: "nawapharma",
                description: "nawa",
                img: nawa
            },
            {
                title: "simagrup",
                description: "sima",
                img: sima
            }
        ]
        return (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {data.map(({ img, title, description, }, key) => (
                    <Card key={key} className="mt-6 w-full ">
                        <CardBody>
                            <img src={img} className="h-56 md:h-62 w-full" />
                            <Typography variant="h5" color="blue" className="m-2">
                                {title}
                            </Typography>
                            <Typography color="gray" className="mt-1 font-normal">
                                {t(`programming.${description}`)}
                            </Typography>
                        </CardBody>
                    </Card >
                ))}
            </div>
        )
    }

    return (
        <>
            <div className="flex flex-col items-center justify-center ">
                <div className="text-center ">
                    <Typography variant="h4" color="blue" textGradient className="text-2xl">
                        {t("programming.business")}
                    </Typography>
                    <Typography color="gray" className="mt-1 font-normal">
                        {t("programming.business_desc")}
                    </Typography>
                </div>
                <div className="border-blue-500 rounded-lg border-2 md:w-[25%]  w-[90%] m-5"></div>
            </div>
            <Programing />
        </>
    );
}