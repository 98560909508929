export const users = [
  {
    id: 1,
    name: "موقع شخصي ",
    price: 200,
    addition: [
      {
        id: 1,
        name: "تصميم خاص",
        price: 300,
        addition: [
          {
            id: 1,
            name: "موقع صفحة واحدة ",
            price: 50,
          },
          {
            id: 2,
            name: "موقع  متعدد الصفحات  ",
            price: 100,
          },
        ],
      },
      {
        id: 2,
        name: "تصميم عادي ",
        price: 50,
        addition: [
          {
            id: 1,
            name: "موقع صفحة واحدة ",
            price: 0,
          },
          {
            id: 2,
            name: "موقع  متعدد الصفحات  ",
            price: 150,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "موقع شركة",
    price: 300,
    addition: [
      {
        id: 1,
        name: "تصميم خاص",
        price: 300,
        addition: [
          {
            id: 1,
            name: "موقع صفحة واحدة ",
            price: 50,
          },
          {
            id: 2,
            name: "موقع  متعدد الصفحات  ",
            price: 150,
          },
        ],
      },
      {
        id: 2,
        name: "تصميم عادي ",
        price: 50,
        addition: [
          {
            id: 1,
            name: "موقع صفحة واحدة ",
            price: 0,
          },
          {
            id: 2,
            name: "موقع  متعدد الصفحات  ",
            price: 150,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "متجر إلكتروني",
    price: 400,
    addition: [
      {
        id: 1,
        name: "فئة واحدة",
        price: 0,
        addition: [
          {
            id: 1,
            name: "أضافة تعليق للمتجر ",
            price: 30,
          },
          {
            id: 2,
            name: " أضافة تعليق للمنتج  ",
            price: 30,
          },
          {
            id: 3,
            name: " أضافة تقييم نجوم ",
            price: 30,
          },
          {
            id: 4,
            name: " أضافة قائمة المفضلة ",
            price: 70,
          },
          {
            id: 5,
            name: " أضافة تخفيضات  ",
            price: 50,
          },
          {
            id: 6,
            name: " أضافة فلتر للمنتجات  ",
            price: 150,
          },
          {
            id: 7,
            name: " أضافة صفحة هبوط   ",
            price: 50,
          },
          {
            id: 8,
            name: " أضافة تسجيل للمستخدم   ",
            price: 50,
          },
          {
            id: 9,
            name: " أضافة  احجام او الوان للمنتج   ",
            price: 30,
          },
          {
            id: 10,
            name: " أضافة طريقة دفع ",
            price: 200,
          },
          {
            id: 11,
            name: " أضافة نظام مخازن ",
            price: 50,
          },
        ],
      },
      {
        id: 2,
        name: "متعدد الفئات",
        price: 200,
        addition: [
          {
            id: 1,
            name: "أضافة تعليق للمتجر ",
            price: 30,
          },
          {
            id: 2,
            name: " أضافة تعليق للمنتج  ",
            price: 30,
          },
          {
            id: 3,
            name: " أضافة تقييم نجوم ",
            price: 30,
          },
          {
            id: 4,
            name: " أضافة قائمة المفضلة ",
            price: 70,
          },
          {
            id: 5,
            name: " أضافة تخفيضات  ",
            price: 50,
          },
          {
            id: 6,
            name: " أضافة فلتر للمنتجات  ",
            price: 150,
          },
          {
            id: 7,
            name: " أضافة صفحة هبوط   ",
            price: 50,
          },
          {
            id: 8,
            name: " أضافة تسجيل للمستخدم   ",
            price: 50,
          },
          {
            id: 9,
            name: " أضافة  احجام او الوان للمنتج   ",
            price: 30,
          },
          {
            id: 10,
            name: " أضافة طريقة دفع ",
            price: 200,
          },
          {
            id: 11,
            name: " أضافة نظام مخازن ",
            price: 50,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    name: "سستم خاص ",
    price: 500,
  },
  {
    id: 5,
    name: "تطبيق هاتف ",
    price: 700,
  },
];
