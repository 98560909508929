import React, { useRef, useState } from "react";
import { Footer } from "../../layouts/Footer";
import {
  Button,
  Card,
  CardBody,
  Input,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { CiAlignBottom, CiServer } from "react-icons/ci";
import { SiCloudflarepages } from "react-icons/si";
import { TimelineWithIcon } from "./Content";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_b9206dr",
        "template_vnvhr23",
        form.current,
        "aoHGj9I2_xFeo9n2Q"
      )
      .then(
        (result) => {
          console.log(result.text);
          setName("");
          setEmail("");
          setMessage("");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const [t] = useTranslation("global");
  return (
    <>
      <div className="flex justify-center items-center relative">
        <div className="w-full relative">
          <div className="md:h-[350px] h-[350px] w-full object-cover object-center bg-[#013356]"></div>
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
            <h2 className="text-white text-3xl md:text-4xl font-bold">
              {t("contact.contact")}
            </h2>
            <hr className="mt-2 border-t-2 border-white w-16 mx-auto" />
          </div>
        </div>
      </div>

      <div className="w-[90%] md:w-5/6 absolute md:top-[300px] top-[250px] bottom-0 left-1/2 transform -translate-x-1/2">
        <figcaption className="items-center rounded-xl backdrop-saturate-200 backdrop-blur-2xl bg-opacity-80 border border-blue-gray-50/80 bg-[#fdfeff] p-6 shadow-lg shadow-black/5 saturate-100 overflow-hidden">
          <div className="flex flex-col items-center justify-center space-y-4 m-6">
            <div className="md:flex items-center md:w-auto w-72 text-center">
              <div>
                <TimelineWithIcon />
              </div>
              <div className="border-blue-500 rounded-lg border-2 md:h-[40vh] m-5"></div>
              <div className="md:flex-1 justify-between flex flex-col items-center">
                <Card color="transparent" shadow={false}>
                  <form
                    ref={form}
                    onSubmit={sendEmail}
                    className="mt-8 mb-2 w-72 max-w-screen-lg sm:w-96"
                  >
                    <div className="mb-4 flex flex-col gap-6">
                      <Input
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        value={name}
                        name="user_name"
                        size="lg"
                        label={t("contact.name")}
                      />
                      <Input
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                        name="user_email"
                        size="lg"
                        label={t("contact.email")}
                      />
                      <Textarea
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                        value={message}
                        name="message"
                        label={t("contact.message")}
                      />
                    </div>
                    <Button
                      type="submit"
                      disabled={false}
                      className="mt-6"
                      fullWidth
                    >
                      {t("contact.btn")}
                    </Button>
                  </form>
                </Card>
              </div>
            </div>
          </div>
        </figcaption>
        <Footer />
      </div>
    </>
  );
};

export default Contact;
