import React from 'react'
import { Footer } from '../../layouts/Footer'
import {
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import { CiAlignBottom, CiServer } from "react-icons/ci";
import { SiCloudflarepages } from "react-icons/si";
import { ProductsCard } from './Card';
const Product = () => {
  return (
    <>
      <div className="flex justify-center items-center relative">
        <div className="w-full relative">
          <div
            className="md:h-[350px] h-[350px] w-full object-cover object-center bg-[#013356]"
          ></div>
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
            <h2 className="text-white text-3xl md:text-4xl font-bold">المنتجات </h2>
            <hr className="mt-2 border-t-2 border-white w-16 mx-auto" />
          </div>
        </div>
      </div>


      <div className="w-[90%] md:w-5/6 absolute md:top-[300px] top-[250px] bottom-0 left-1/2 transform -translate-x-1/2">
        <figcaption className="items-center rounded-xl backdrop-saturate-200 backdrop-blur-2xl bg-opacity-80 border border-blue-gray-50/80 bg-[#fdfeff] p-6 shadow-lg shadow-black/5 saturate-100">
          <div dir="rtl" className="flex flex-col items-center space-y-4 mb-6">
            <div dir="rtl" className="grid  mb-8 grid-cols-1 gap-4">
              <ProductsCard />
              <ProductsCard />
              <ProductsCard />
            </div>
          </div>
        </figcaption>
        <Footer />
      </div>
    </>
  )
}

export default Product