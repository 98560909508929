import React, { useState } from "react";
import logo from './logod.png';
import { ImWhatsapp, ImFacebook } from "react-icons/im";
import { BsInstagram } from "react-icons/bs";
import { GrLinkedinOption } from "react-icons/gr";
import { animateScroll as scroll } from 'react-scroll';



import {
   Navbar,
   Collapse,
   Typography,
   Button,
   IconButton,
   List,
   ListItem,
   Menu,
   MenuHandler,
   MenuList,
   MenuItem,
   Chip,
   Select,
   Option,
} from "@material-tailwind/react";
import {
   ChevronDownIcon,
   EnvelopeIcon,
   HomeIcon,
   Bars3Icon,
   XMarkIcon,
   BriefcaseIcon,
   CodeBracketIcon,
   PaintBrushIcon,
   UsersIcon,
   Square3Stack3DIcon,
   Squares2X2Icon,
   FaceSmileIcon,
   BoltIcon,
   GiftIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const colors = {
   blue: "bg-blue-50 text-blue-500",
   orange: "bg-orange-50 text-orange-500",
   green: "bg-green-50 text-green-500",
   "blue-gray": "bg-blue-gray-50 text-blue-gray-500",
   purple: "bg-purple-50 text-purple-500",
   teal: "bg-teal-50 text-teal-500",
   cyan: "bg-cyan-50 text-cyan-500",
   pink: "bg-pink-50 text-pink-500",
};

const navListMenuItems = [
   {
      color: "blue",
      icon: BriefcaseIcon,
      title: "about",
      soon: "",
      description: "about_desc",
      to: "/About"
   },
   {
      color: "purple",
      icon: Squares2X2Icon,
      title: "products",
      soonar: (
         <Typography variant="small" color="gray" className="font-normal">
            <Chip
               size="sm"
               color="green"
               variant="ghost"
               value="قريباً"
               className="capitalize"
            />
         </Typography>

      ),
      soonen: (
         <Typography variant="small" color="gray" className="font-normal">
            <Chip
               size="sm"
               color="green"
               variant="ghost"
               value="Coming Soon"
               className="capitalize"
            />
         </Typography>
      ),
      description: "products_desc"
   },
   {
      color: "orange",
      icon: CodeBracketIcon,
      title: "programming",
      soon: "",
      description: "programming_desc",
      to: "/Programming"
   },
   {
      color: "green",
      icon: PaintBrushIcon,
      title: "designs",
      soon: "",
      description: "designs_desc",
      to: "/Design"
   }

];




function NavListMenu({ setOpenNav, openNav, t }) {
   const [isMenuOpen, setIsMenuOpen] = React.useState(false);
   const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
   const scrollToTop = () => {
      scroll.scrollToTop();
      setOpenNav(!openNav)
   };
   const renderItems = navListMenuItems.map(
      ({ icon, title, description, color, to, soonar, soonen }, key) => (
         <Link dir={t("dir")} to={to} key={key}>
            <MenuItem onClick={scrollToTop} className="flex items-center gap-4 rounded-lg">
               <div className={`rounded-lg p-5 ${colors[color]}`}>
                  {React.createElement(icon, {
                     strokeWidth: 2,
                     className: "h-6 w-6",
                  })}
               </div>
               <div>
                  <Typography
                     variant="h6"
                     color="blue-gray"
                     className="flex items-center text-sm"
                  >
                     {t(`navbar.${title}`)}

                  </Typography>
                  {t("lang") === "ar" ? soonar : soonen}
                  <Typography variant="small" color="gray" className="font-normal">
                     {t(`navbar.${description}`)}
                  </Typography>
               </div>
            </MenuItem>
         </Link>
      )
   );

   return (
      <React.Fragment>
         <Menu
            dir={t("dir")}
            open={isMenuOpen}
            handler={setIsMenuOpen}
            offset={{ mainAxis: 20 }}
            placement="bottom"
            allowHover={true}
         >
            <MenuHandler>
               <Typography as="div" variant="small" className="font-normal">
                  <ListItem
                     className="flex items-center gap-2 py-2 pr-4"
                     selected={isMenuOpen || isMobileMenuOpen}
                     onClick={() => setIsMobileMenuOpen((cur) => !cur)}
                  >
                     <Square3Stack3DIcon className="h-[18px] w-[18px]" />
                     {t("navbar.pages")}
                     <ChevronDownIcon
                        strokeWidth={2.5}
                        className={`hidden h-3 w-3 transition-transform lg:block ${isMenuOpen ? "rotate-180" : ""
                           }`}
                     />
                     <ChevronDownIcon
                        strokeWidth={2.5}
                        className={`block h-3 w-3 transition-transform lg:hidden ${isMobileMenuOpen ? "rotate-180" : ""
                           }`}
                     />
                  </ListItem>
               </Typography>
            </MenuHandler>
            <MenuList className="hidden max-w-screen-lg rounded-xl lg:block">
               <ul className="grid grid-cols-2 gap-y-2">{renderItems}</ul>
            </MenuList>
         </Menu>
         <div className="block lg:hidden">
            <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
         </div>
      </React.Fragment>
   );
}
function NavList({ setOpenNav, openNav, t }) {
   const scrollToTop = () => {
      scroll.scrollToTop();
      setOpenNav(!openNav)

   };

   return (
      <List dir={t("dir")} className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1 ">
         <Typography
            as="div"
            variant="small"
            color="blue-gray"
            className="font-normal"
         >
            <Link to="/" >
               <ListItem onClick={scrollToTop} className="flex items-center gap-2 py-2 pr-4">
                  <HomeIcon className="h-[18px] w-[18px]" />
                  {t("navbar.home")}
               </ListItem>
            </Link>
         </Typography>
         <NavListMenu t={t} setOpenNav={setOpenNav} openNav={openNav} />
         <Typography
            as="div"

            variant="small"
            color="blue-gray"
            className="font-normal"
         >
            <Link to="/Contact" >
               <ListItem onClick={scrollToTop} className="flex items-center gap-2 py-2 pr-4">
                  <EnvelopeIcon className="h-[18px] w-[18px]" />
                  {t("navbar.contact")}
               </ListItem>
            </Link>
         </Typography>
      </List>
   );
}

const Navbars = () => {
   const [openNav, setOpenNav] = React.useState(false);
   const [Nav, setNav] = React.useState();
   const [t, i18n] = useTranslation("global");
   React.useEffect(() => {
      window.addEventListener(
         "resize",
         () => window.innerWidth >= 960 && setOpenNav(false)
      );
   }, []);

   const handleLanguage = (lang) => {
      i18n.changeLanguage(lang);
   }
   const handleLanguageS = (lang) => {
      i18n.changeLanguage(lang);
      setOpenNav(!openNav);
   }



   return (
      <div className='fixed top-0 left-2/4 transform -translate-x-2/4 z-50 w-[calc(100%-4rem)] px-6 '>
         <Navbar className="mx-auto max-w-screen-xl px-4 py-2 mt-10">

            <div className="flex items-center justify-between text-blue-gray-900">
               <Typography
                  as="div"

                  variant="h6"
                  className="mr-4 cursor-pointer py-1.5 lg:ml-2"
               >
                  <img className=" w-32 h-auto" src={logo} />
               </Typography>
               <div className="hidden lg:block">
                  <NavList t={t} openNav={Nav} setOpenNav={setNav} />
               </div>
               <div className="hidden gap-2 lg:flex">
                  <Select label={t("navbar.dil")}>
                     <Option onClick={() => { handleLanguage("ar") }}>العربية</Option>
                     <Option onClick={() => { handleLanguage("en") }}>English</Option>

                  </Select>
               </div>
               <IconButton
                  variant="text"
                  color="blue-gray"
                  className="lg:hidden"
                  onClick={() => setOpenNav(!openNav)}
               >
                  {openNav ? (
                     <XMarkIcon className="h-6 w-6" strokeWidth={2} />
                  ) : (
                     <Bars3Icon className="h-6 w-6" strokeWidth={2} />
                  )}
               </IconButton>
            </div>
            <Collapse open={openNav}>
               <NavList t={t} openNav={openNav} setOpenNav={setOpenNav} />
               <div className="flex w-full flex-nowrap items-center gap-2 lg:hidden">
                  <div className="w-72">
                     <Select label={t("navbar.dil")}>
                        <Option onClick={() => { handleLanguageS("ar") }}>العربية</Option>
                        <Option onClick={() => { handleLanguageS("en") }}>English</Option>

                     </Select>
                  </div>
               </div>
            </Collapse>
         </Navbar>
      </div>
   );
}
export default Navbars;

