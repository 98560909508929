import React from "react";
import Navbars from "./components/layouts/Navbar";
import Home from "./components/pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./components/pages/About";
import Product from "./components/pages/products";
import Programming from "./components/pages/Programming";
import Design from "./components/pages/Designs";
import Contact from "./components/pages/Contact";
import ProjectForm from "./components/project/printe";
const App = () => {
  return (
    <div className="font-sans">
      <BrowserRouter>
        <Navbars />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Programming" element={<Programming />} />
          <Route path="/Design" element={<Design />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/ProjectForm" element={<ProjectForm />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
