import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineHeader,
  TimelineIcon,
  TimelineBody,
  Typography,
} from "@material-tailwind/react";
import { BsTelephoneFill } from "react-icons/bs";
import { IoLocationSharp, IoMail } from "react-icons/io5";
import { BellIcon, CurrencyDollarIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

export function TimelineWithIcon() {
  const [t] = useTranslation("global");
  return (
    <div className="md:w-[100%]">
      <Timeline>
        <TimelineItem>
          <TimelineConnector />
          <TimelineHeader>
            <TimelineIcon className="p-2">
              <BsTelephoneFill className="h-4 w-4" />
            </TimelineIcon>
            <Typography variant="h5" color="blue-gray">
              {t("contact.phone")}
            </Typography>
          </TimelineHeader>
          <TimelineBody className="pb-8">
            <Typography className="font-normal text-gray-600">
              0 (538) 501 94 09
            </Typography>
          </TimelineBody>
        </TimelineItem>
        <TimelineItem>
          <TimelineConnector />
          <TimelineHeader>
            <TimelineIcon className="p-2">
              <IoMail className="h-4 w-4" />
            </TimelineIcon>
            <Typography variant="h5" color="blue-gray">
              {t("contact.email")}
            </Typography>
          </TimelineHeader>
          <TimelineBody className="pb-8">
            <Typography className="font-normal text-gray-600">
              info@alayadids.com
            </Typography>
          </TimelineBody>
        </TimelineItem>
        <TimelineItem>
          <TimelineHeader>
            <TimelineIcon className="p-2">
              <IoLocationSharp className="h-4 w-4" />
            </TimelineIcon>
            <Typography variant="h5" color="blue-gray">
              {t("contact.address")}
            </Typography>
          </TimelineHeader>
          <TimelineBody>
            <Typography className="font-normal text-gray-600 text-left">
              Sakarya CD. Yenigun MAH. BINA_ADI:HOROZLU-A-BLOK No: 13/4
              Adapazarı Sakarya Turkey
            </Typography>
          </TimelineBody>
        </TimelineItem>
      </Timeline>
    </div>
  );
}
