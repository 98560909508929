import React from "react";
import { CiAlignBottom, CiBezier, CiPen, CiServer } from "react-icons/ci";
import { Si4Chan, SiAdobeaftereffects, SiAdobecreativecloud, SiAdobeillustrator, SiAdobephotoshop, SiCloudflarepages, SiGooglesearchconsole, SiWindowsterminal } from "react-icons/si";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  CardFooter
} from "@material-tailwind/react";
import {
  PaintBrushIcon,
  CodeBracketIcon,
} from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

export function TabsWithIcon() {
  const [t] = useTranslation("global");

  const Design = () => {
    return (
      <>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">

        <Card className="mt-6 w-full ">
            <CardBody>
              <CiServer size={44} className=" text-blue-500 mb-3" />
              <Typography variant="h5" color="blue" className="mb-2">
                {t("home.logo")}
              </Typography>
              <Typography color="gray" className=" font-normal">
                {t("home.logo_desc")}
              </Typography>
            </CardBody>
          </Card >
          <Card className="mt-6 w-full ">
            <CardBody>
              <CiServer size={44} className=" text-blue-500 mb-3" />
              <Typography variant="h5" color="blue" className="mb-2">
                {t("home.post")}
              </Typography>
              <Typography color="gray" className=" font-normal">
                {t("home.post_desc")}
              </Typography>
            </CardBody>
          </Card >
          <Card className="mt-6 w-full ">
            <CardBody>
              <CiServer size={44} className=" text-blue-500 mb-3" />
              <Typography variant="h5" color="blue" className="mb-2">
                {t("home.video")}
              </Typography>
              <Typography color="gray" className=" font-normal">
                {t("home.video_desc")}
              </Typography>
            </CardBody>
          </Card >


        </div>

      </>
    )
  }
  const Programing = () => {
    return (
      <>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">

          <Card className="mt-6 w-full ">
            <CardBody>
              <CiServer size={44} className=" text-blue-500 mb-3" />
              <Typography variant="h5" color="blue" className="mb-2">
                {t("home.websites")}
              </Typography>
              <Typography color="gray" className=" font-normal">
                {t("home.websites_desc")}
              </Typography>
            </CardBody>
          </Card >
          <Card className="mt-6 w-full ">
            <CardBody>
              <CiAlignBottom size={44} className=" text-blue-500 mb-3" />
              <Typography variant="h5" color="blue" className="mb-2">
                {t("home.systems")}
              </Typography>
              <Typography color="gray" className=" font-normal">
                {t("home.systems_desc")}
              </Typography>
            </CardBody>
          </Card >
          <Card className="mt-6 w-full ">
            <CardBody>
              <SiCloudflarepages size={44} className=" text-blue-500 mb-3" />
              <Typography variant="h5" color="blue" className="mb-2">
                {t("home.mobile")}
              </Typography>
              <Typography color="gray" className=" font-normal">
                {t("home.mobile_desc")}
              </Typography>
            </CardBody>
          </Card >
          <Card className="mt-6 w-full ">
            <CardBody>
              <SiCloudflarepages size={44} className=" text-blue-500 mb-3" />
              <Typography variant="h5" color="blue" className="mb-2">
                {t("home.QR")}
              </Typography>
              <Typography color="gray" className=" font-normal">
                {t("home.QR_desc")}
              </Typography>
            </CardBody>
          </Card >



        </div>
      </>
    )
  }
  const data = [
    {
      label: "programming",
      value: "البرمجة",
      icon: CodeBracketIcon,
      desc: <Programing />,
    },
    {
      label: "designs",
      value: "التصميم",
      icon: PaintBrushIcon,
      desc: <Design />,
    }
  ];
  return (
    <Tabs dir={t("dir")} value="البرمجة">
      <TabsHeader>
        {data.map(({ label, value, icon }) => (
          <Tab key={value} value={value}>
            <div className="flex items-center gap-2">
              {React.createElement(icon, { className: "w-5 h-5" })}
              {t(`home.${label}`)}
            </div>
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, desc }) => (
          <TabPanel key={value} value={value}>
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}