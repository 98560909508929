import React from "react";
import {
    Card,
    CardBody,
    Typography,
} from "@material-tailwind/react";
import fortea from './images/fortea.jpg'
import mastka from './images/mastka.jpg'
import somar from './images/somar.jpg'
import arkadaslar from './images/arkadaslar.jpg'
import { useTranslation } from "react-i18next";


const Logo = () => {
    const [t] = useTranslation("global");
    
    const data = [

        {
            title: "SUMER",
            description: "somar",
            img: somar
        },
        {
            title: "MASTIKA",
            description: "mastka",
            img: mastka
        },
        {
            title: "ARKADAŞLAR",
            description: "arkadaslar",
            img: arkadaslar
        },
        {
            title: "FOR TEA",
            description: "fortea",
            img: fortea
        }
    ]
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {data.map(({ img, title, description, }, key) => (
                <Card key={key} className="mt-6 w-full ">
                    <CardBody>
                        <img src={img} className="h-56 md:h-62 w-full" />
                        <Typography variant="h5" color="blue" className="m-2">
                            {title}
                        </Typography>
                        <Typography color="gray" className="mt-1 font-normal">
                            {t(`designs.${description}`)}
                        </Typography>
                    </CardBody>
                </Card >
            ))}
        </div>
    )
}
export default Logo;