
import { ar } from '../DataLang';
import { en } from '../DataLang';


export const reducerGetData = (state = {lang: ar}, action) => {
  switch (action.type) {
    case 'ar':
      return { ...state, lang: state= ar };
    case 'en':
      return { ...state, lang: state= en };
    default:
      return state;
  }
};

