import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
    Textarea
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import emailjs from '@emailjs/browser';
import { useRef, useState } from "react";

export function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_b9206dr', 'template_vnvhr23', form.current, 'aoHGj9I2_xFeo9n2Q')
            .then((result) => {
                console.log(result.text);
                setName('');
                setEmail('');
                setMessage('');
            }, (error) => {
                console.log(error.text);
            });

    };

    const [t] = useTranslation("global");
    return (
        <div className="md:flex items-center  md:w-[650px]  text-center">
            <div>
                <Typography variant="h4" color="blue">
                    {t("home.contact")}
                </Typography>
                <Typography color="gray" className="mt-1 font-normal">
                    {t("home.contact_desc")}
                </Typography>
            </div>
            <div className="border-blue-500 rounded-lg border-2 md:h-72 m-5 "></div>
            <div className="md:flex-1 justify-between flex flex-col items-center">
                <Card color="transparent" shadow={false}>
                    <form ref={form} onSubmit={sendEmail} className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
                        <div className="mb-4 flex flex-col gap-6">
                            <Input onChange={(e) => { setName(e.target.value) }} value={name} name="user_name" size="lg" label={t("home.contact_name")} />
                            <Input onChange={(e) => { setEmail(e.target.value) }} value={email} name="reply_to" size="lg" label={t("home.contact_email")} />
                            <Textarea onChange={(e) => { setMessage(e.target.value) }} value={message} name="message" label={t("home.contact_message")} />
                        </div>
                        <Button type="submit" className="mt-6" fullWidth>
                            {t("home.contact_btn")}
                        </Button>
                    </form>
                </Card>
            </div>

        </div>
    );
}