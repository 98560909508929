import React from "react";
import {
    Card,
    CardBody,
    Typography

} from "@material-tailwind/react";
import Turizim from './images/test.hevc.mp4';
import Cene from './images/cene.hevc.mp4';
import Logo from './images/cevirilecek.hevc.mp4';
import { useTranslation } from "react-i18next";


const Video = () => {
    const [t] = useTranslation("global");
    const data = [
        {
            title: "tourism video",
            description: "tourist",
            img: Turizim
        },
        {
            title: "Announcement video",
            description: "distinctive",
            img: Cene
        },
        {
            title: "Logo video",
            description: "logo_video",
            img: Logo
        }
    ]
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {data.map(({ img, title, description, }, key) => (
                <Card key={key} className="mt-6 w-full ">
                    <CardBody>
                        <video className="h-56 md:h-62 w-full " controls>
                            <source src={img} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <Typography variant="h5" color="blue" className="m-2">
                            {title}
                        </Typography>
                        <Typography color="gray" className="mt-1 font-normal">
                            {t(`designs.${description}`)}
                        </Typography>
                    </CardBody>
                </Card >
            ))}
        </div>
    )
}
export default Video;
