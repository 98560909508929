import React from 'react'
import { Footer } from '../../layouts/Footer'
import DesignsCard from './Card';
import { DesignsTabs } from './Tabs';
import { useTranslation } from 'react-i18next';



const Design = () => {
const [t]= useTranslation("global");

  return (
    <>
      <div className="flex justify-center items-center relative">
        <div className="w-full relative">
          <div
            className="md:h-[350px] h-[350px] w-full object-cover object-center bg-[#013356]"
          ></div>
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
            <h2 className="text-white text-3xl md:text-4xl font-bold">{t("designs.designs")}</h2>
            <hr className="mt-2 border-t-2 border-white w-16 mx-auto" />
          </div>
        </div>
      </div>


      <div className="w-[90%] md:w-5/6 absolute md:top-[300px] top-[250px] bottom-0 left-1/2 transform -translate-x-1/2">
        <figcaption className="items-center rounded-xl backdrop-saturate-200 backdrop-blur-2xl bg-opacity-80 border border-blue-gray-50/80 bg-[#fdfeff] p-6 shadow-lg shadow-black/5 saturate-100">
          <div dir={t("dir")} className="flex flex-col items-center space-y-4 mb-6">
            <DesignsCard/>
            <DesignsTabs/>
          </div>
        </figcaption>
        <Footer />
      </div>
    </>
  )
}

export default Design