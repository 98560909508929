import React from "react";
import {
    Card,
    CardBody,
    Typography
} from "@material-tailwind/react";
import Avion from './images/avion.jpg'
import Fast from './images/fast.jpg'
import Irak from './images/irak.jpg'
import Kozco from './images/kozco.jpg'
import { useTranslation } from "react-i18next";


const Post = () => {
    const[t] = useTranslation("global")
    const data = [
        {
            title: "Avion",
            description: "avion",
            img: Avion
        },
        {
            title: "Fast Cash",
            description: "fast",
            img: Fast
        },
        {
            title: "Iraq star",
            description: "irak",
            img: Irak
        },
        {
            title: "Kozcuoğlu",
            description: "kozco",
            img: Kozco
        }
    ]
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {data.map(({ img, title, description, }, key) => (
                <Card key={key} className="mt-6 w-full ">
                    <CardBody >
                        <img src={img} className="h-56 md:h-62 w-full" />
                        <Typography variant="h5" color="blue" className="m-2">
                            {title}
                        </Typography>
                        <Typography color="gray" className="mt-1 font-normal">
                            {t(`designs.${description}`)}
                        </Typography>
                    </CardBody>
                </Card >
            ))}
        </div>
    )
}
export default Post;