import React from 'react'
import {
  Card,
  CardBody,
  Typography,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { useTranslation } from 'react-i18next';
import { PiComputerTowerThin, PiFileImageThin, PiPenNibLight, PiPenNibThin, PiSidebarLight, PiVideoThin } from "react-icons/pi";



function Icon({ open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${open === true ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}
const DesignsCard = () => {
  const [openAcc1, setOpenAcc1] = React.useState(true);
  const [openAcc2, setOpenAcc2] = React.useState(true);
  const [openAcc3, setOpenAcc3] = React.useState(true);
  const [t] = useTranslation("global")

  const handleOpenAcc1 = () => setOpenAcc1((cur) => !cur);
  const handleOpenAcc2 = () => setOpenAcc2((cur) => !cur);
  const handleOpenAcc3 = () => setOpenAcc3((cur) => !cur);
  return (
    <div className="grid grid-cols-1 mb-8 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <Card className="mt-6 w-full ">
        <CardBody>
          <PiVideoThin size={44} className=" text-blue-500 mb-3 " />
          <Accordion open={openAcc1} icon={<Icon open={openAcc1} />}>
            <AccordionHeader
              onClick={handleOpenAcc1}
              className={`border-b-0 transition-colors  ${openAcc1 === true ? "text-blue-500 hover:!text-blue-700" : ""
                }`}
            >
             {t("designs.video")}

            </AccordionHeader>
            <AccordionBody>
              <Typography variant="lead">
                {t("designs.video_desc")}
              </Typography>
            </AccordionBody>
          </Accordion>
        </CardBody>
      </Card>
      <Card className="mt-6 w-full ">
        <CardBody>
          <PiFileImageThin size={44} className=" text-blue-500 mb-3 " />
          <Accordion open={openAcc2} icon={<Icon open={openAcc2} />}>
            <AccordionHeader
              onClick={handleOpenAcc2}
              className={`border-b-0 transition-colors  ${openAcc2 === true ? "text-blue-500 hover:!text-blue-700" : ""
                }`}
            >
              {t("designs.post")}

            </AccordionHeader>
            <AccordionBody>
              <Typography variant="lead">
                {t("designs.post_desc")}
              </Typography>
            </AccordionBody>
          </Accordion>
        </CardBody>
      </Card>
      <Card className="mt-6 w-full ">
        <CardBody>
          <PiPenNibThin size={44} className=" text-blue-500 mb-3 " />
          <Accordion open={openAcc3} icon={<Icon open={openAcc3} />}>
            <AccordionHeader
              onClick={handleOpenAcc3}
              className={`border-b-0 transition-colors  ${openAcc3 === true ? "text-blue-500 hover:!text-blue-700" : ""
                }`}
            >
              {t("designs.logo")}

            </AccordionHeader>
            <AccordionBody>
              <Typography variant="lead">
                {t("designs.logo_desc")}
              </Typography>
            </AccordionBody>
          </Accordion>
        </CardBody>
      </Card>

    </div>
  )
}

export default DesignsCard