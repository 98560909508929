import React from 'react';
import { Footer } from '../../layouts/Footer';
import Header from './content/Heder';
import Img from './slider.webp';
const Home = () => {
    return (
        <>
            <div className="flex justify-center items-center">
                <div className="w-full">
                    <img
                        className="md:h-[700px] h-[450px] w-full object-cover object-center"
                        src={Img}
                        alt="nature image"
                    />
                </div>
            </div>
            <div className="w-[90%] md:w-5/6 absolute md:top-[600px] top-[350px] bottom-0 left-1/2 transform -translate-x-1/2">
                <figcaption className="items-center rounded-xl backdrop-saturate-200 backdrop-blur-2xl bg-opacity-80 border border-blue-gray-50/80 bg-[#fdfeff] p-6 shadow-lg shadow-black/5 saturate-100">
                    <Header />
                </figcaption>
                <Footer />
            </div>
        </>
    );
}

export default Home;
