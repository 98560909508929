import React from 'react'
import {
    CardBody,
    Typography,
} from "@material-tailwind/react";
import { CiAlignBottom, CiServer } from "react-icons/ci";
import { SiCloudflarepages } from "react-icons/si";
import { useTranslation } from 'react-i18next';


const Features = () => {
    const [t] = useTranslation("global");

    return (

        <div dir={t("dir")} className="grid grid-cols-1 mb-20 mt-14 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="mt-6 w-full   ">
                <CardBody>
                    <CiServer size={44} className=" text-blue-500 mb-3 " />
                    <Typography variant="h5" color="blue" className="mb-2">
                        {t("home.professionalism")}
                    </Typography>
                    <Typography color="gray" className=" font-normal">
                        {t("home.professionalism_desc")}
                    </Typography>
                </CardBody>
            </div >
            <div className="mt-6 w-full  ">
                <CardBody>
                    <CiAlignBottom size={44} className=" text-blue-500 mb-3" />
                    <Typography variant="h5" color="blue" className="mb-2">
                        {t("home.Innovation")}
                    </Typography>
                    <Typography color="gray" className=" font-normal">
                        {t("home.Innovation_desc")}
                    </Typography>
                </CardBody>
            </div >
            <div className="mt-6 w-full  ">
                <CardBody>
                    <SiCloudflarepages size={44} className=" text-blue-500 mb-3" />
                    <Typography variant="h5" color="blue" className="mb-2">
                        {t("home.Customer")}
                    </Typography>
                    <Typography color="gray" className=" font-normal">
                        {t("home.Customer_desc")}
                    </Typography>
                </CardBody>
            </div >
        </div>
    )
}

export default Features