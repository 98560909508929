import React from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    Typography,
    Button,
} from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProdactItem = [
    {
        title: 'سستم الفواتير',
        desc: 'llvdcvsd;cjs;ocjas;ocs;aosca;osc;oscs',
    },
    {
        title: 'سستم الفواتير',
        desc: 'llvdcvsd;cjs;ocjas;ocs;aosca;osc;oscs',
    },
    {
        title: 'سستم الفواتير',
        desc: 'llvdcvsd;cjs;ocjas;ocs;aosca;osc;oscs',
    },
    {
        title: 'سستم الفواتير',
        desc: 'llvdcvsd;cjs;ocjas;ocs;aosca;osc;oscs',
    },
]

const Prodact = () => {
    const [t]= useTranslation("global");


    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
            {ProdactItem.map(({ title, desc }, key) => {
                return (
                    <Card key={key} className="flex flex-col md:flex-row">
                        <CardHeader
                            floated={false}
                            className=" md:w-[30%] md:h-[100%] p-0 m-0  md:rounded-bl-xl md:rounded-tl-xl md:rounded-br-none rounded-br-none rounded-bl-none md:rounded-tr-none  relative"
                        >
                            <img
                                src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
                                alt="card-image"
                                className="h-full w-full object-cover md:object-center md:rounded-none sm:h-full sm:w-full sm:rounded-md"
                            />
                        </CardHeader>
                        <CardBody className="w-full md:w-[70%]">
                            <Typography variant="h6" color="gray" className="mb-4 uppercase">
                                {title}
                            </Typography>
                            <Typography color="gray" className="mb-8 font-normal">
                                {desc}
                            </Typography>
                            <Link  className="inline-block">
                                <Button variant="text" className="flex items-center gap-2">
                                   {t("home.products_red")}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        className="h-4 w-4"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                        />
                                    </svg>
                                </Button>
                            </Link>
                        </CardBody>
                    </Card>
                )
            })}
        </div>
    )
}

export default Prodact