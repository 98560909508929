import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { reducerGetData } from "../reducer/reducerGetData";



const rootReducer = combineReducers({
  data: reducerGetData,

});

const store = createStore(rootReducer, applyMiddleware(thunk));


export default store;
