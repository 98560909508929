import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  CardFooter,
  Avatar
} from "@material-tailwind/react";
import { TabsWithIcon } from "./Services";
import Prodact from "./Prodact";
import About from "./About";
import { Contact } from "./Contact";
import { CiAlignBottom, CiBezier, CiPen, CiServer } from "react-icons/ci";
import { Si4Chan, SiAdobeaftereffects, SiAdobecreativecloud, SiAdobeillustrator, SiAdobephotoshop, SiCloudflarepages, SiGooglesearchconsole, SiWindowsterminal } from "react-icons/si";
import Team from "./Team";
import Features from "./Features";
import { useTranslation } from "react-i18next";

const Heder = () => {
  const [t] = useTranslation("global");

  return (
    <>
      <div dir={t("dir")} className="flex flex-col items-center space-y-4 mb-6">

        {/* About */}
        <Typography variant="h3" color="blue" textGradient className="text-2xl text-center">
          {t("home.Alayadi_Digital_Solutions_Company")}
        </Typography>
        <About />
      </div>

      {/* About end */}


      {/* Features */}
      <Features />
      {/* Features end*/}

      {/* Services */}
      <div className=" my-20 space-y-4">
        <div className="flex flex-col items-center justify-center ">
          <div className="text-center ">
            <Typography variant="h4" color="blue" textGradient className="text-2xl">
              {t("home.Services")}
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              {t("home.Services_desc")}
            </Typography>
          </div>
          <div className="border-blue-500 rounded-lg border-2 md:w-[25%]  w-[90%] m-5"></div>
        </div>
        <TabsWithIcon />
      </div>
      {/* Services end */}

      {/* Prodact */}
      {/* <div dir={t("dir")} className=" my-20 space-y-4">
        <div className="flex flex-col items-center justify-center ">
          <div className="text-center ">
            <Typography variant="h4" color="blue" textGradient className="text-2xl">
              {t("home.products")}
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              {t("home.products_desc")}
            </Typography>
          </div>
          <div className="border-blue-500 rounded-lg border-2 md:w-[25%] w-[90%] m-5"></div>
        </div>

        <Prodact />
      </div> */}
      {/* Prodact end */}

      {/* Team */}
      <div dir={t("dir")} className=" my-20 space-y-4">
        <div className="flex flex-col items-center justify-center  ">
          <div className="text-center ">
            <Typography variant="h4" color="blue" textGradient className="text-2xl">
              {t("home.team")}
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              {t("home.team_desc")}
            </Typography>
          </div>
          <div className="border-blue-500 rounded-lg border-2 md:w-[25%] w-[90%] m-5"></div>
        </div>
        <Team />
      </div>
      {/* Team end */}

      {/* Contact */}
      <div className="flex justify-center items-center text-center ">
        <Contact />
      </div>
      {/* Contact end */}

    </>


  );
}
export default Heder



