import React from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    Typography,
    Button,
    CardFooter
} from "@material-tailwind/react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const About = () => {
    const [t] = useTranslation("global");

    return (
        <div className="md:flex items-center  md:w-[650px]  text-center">
            <div className="hidden md:block">
                <Typography variant="h4" color="blue" className="text-center relative mx-4 ">
                    {t("home.about")}
                </Typography>
            </div>
            <div className="border-blue-500 rounded-lg border-2 md:h-44 m-5 hidden md:block"></div>
            <div className="md:flex-1 justify-between flex flex-col items-center">
                <Typography color="gray" className={`mt-1 font-normal md:${t("text")} md:text-lg`}>
                    {t("home.about_desc")}
                </Typography>
                <div className="flex items-center gap-2">
                    <Link to="/About">
                        <Button variant="text" className="flex items-center gap-2">
                            {t("home.about_red")}{" "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="h-5 w-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                />
                            </svg>
                        </Button>
                    </Link>
                </div>
            </div>

        </div>
    )
}

export default About