import React from 'react'
import {
    Card,
    CardBody,
    Typography,
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { CiServer } from "react-icons/ci";
import { useTranslation } from 'react-i18next';
import { PiBrowsersThin, PiCodeBlockThin, PiDesktopThin, PiDesktopTowerThin, PiHamburgerThin } from "react-icons/pi";

function Icon({ open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${open === true ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}


const ProgrammingCard = () => {
    const [t] = useTranslation("global");
    const [openAcc1, setOpenAcc1] = React.useState(true);
    const [openAcc2, setOpenAcc2] = React.useState(true);
    const [openAcc3, setOpenAcc3] = React.useState(true);
    const [openAcc4, setOpenAcc4] = React.useState(true);

    const handleOpenAcc1 = () => setOpenAcc1((cur) => !cur);
    const handleOpenAcc2 = () => setOpenAcc2((cur) => !cur);
    const handleOpenAcc3 = () => setOpenAcc3((cur) => !cur);
    const handleOpenAcc4 = () => setOpenAcc4((cur) => !cur);
    return (
        <div className="grid grid-cols-1 mb-8 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <Card className="mt-6 w-full ">
                <CardBody>
                    <PiDesktopThin size={44} className=" text-blue-500 mb-3 " />
                    <Accordion open={openAcc1} icon={<Icon open={openAcc1} />}>
                        <AccordionHeader
                            onClick={handleOpenAcc1}
                            className={`border-b-0 transition-colors  ${openAcc1 === true ? "text-blue-500 hover:!text-blue-700" : ""
                                }`}
                        >
                            {t("programming.websites")}

                        </AccordionHeader>
                        <AccordionBody>
                            <Typography variant="lead">
                                {t("programming.websites_desc")}
                            </Typography>
                        </AccordionBody>
                    </Accordion>
                </CardBody>
            </Card>
            <Card className="mt-6 w-full ">
                <CardBody>
                    <PiCodeBlockThin size={44} className=" text-blue-500 mb-3 " />
                    <Accordion open={openAcc2} icon={<Icon open={openAcc2} />}>
                        <AccordionHeader
                            onClick={handleOpenAcc2}
                            className={`border-b-0 transition-colors  ${openAcc2 === true ? "text-blue-500 hover:!text-blue-700" : ""
                                }`}
                        >
                            {t("programming.systems")}
                        </AccordionHeader>
                        <AccordionBody>
                            <Typography variant="lead">
                                {t("programming.systems_desc")}
                            </Typography>
                        </AccordionBody>
                    </Accordion>
                </CardBody>
            </Card>
            <Card className="mt-6 w-full ">
                <CardBody>
                    <PiBrowsersThin size={44} className=" text-blue-500 mb-3 " />
                    <Accordion open={openAcc3} icon={<Icon open={openAcc3} />}>
                        <AccordionHeader
                            onClick={handleOpenAcc3}
                            className={`border-b-0 transition-colors  ${openAcc3 === true ? "text-blue-500 hover:!text-blue-700" : ""
                                }`}
                        >
                            {t("programming.mobile")}
                        </AccordionHeader>
                        <AccordionBody>
                            <Typography variant="lead">
                                {t("programming.mobile_desc")}
                            </Typography>
                        </AccordionBody>
                    </Accordion>
                </CardBody>
            </Card>
            <Card className="mt-6 w-full ">
                <CardBody>
                    <PiDesktopTowerThin size={44} className=" text-blue-500 mb-3 " />
                    <Accordion open={openAcc4} icon={<Icon open={openAcc4} />}>
                        <AccordionHeader
                            onClick={handleOpenAcc4}
                            className={`border-b-0 transition-colors  ${openAcc4 === true ? "text-blue-500 hover:!text-blue-700" : ""
                                }`}
                        >
                            {t("programming.QR")}
                        </AccordionHeader>
                        <AccordionBody>
                            <Typography variant="lead">
                                {t("programming.QR_desc")}
                            </Typography>
                        </AccordionBody>
                    </Accordion>
                </CardBody>
            </Card>

        </div>
    )
}

export default ProgrammingCard