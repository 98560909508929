import React from 'react'
import { Footer } from '../../layouts/Footer'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import { CiAlignBottom, CiServer } from 'react-icons/ci';
import { SiCloudflarepages } from 'react-icons/si';
import Team from '../Home/content/Team';
import { useTranslation } from 'react-i18next';
import Features from '../Home/content/Features';
import AboutImg from './about.jpg'
const About = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <>
      <div className="flex justify-center items-center relative">
        <div className="w-full relative">
          <div
            className="md:h-[350px] h-[350px] w-full object-cover object-center bg-[#013356]"
          ></div>
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
            <h2 className="text-white text-3xl md:text-4xl font-bold">{t("about.about")}</h2>
            <hr className="mt-2 border-t-2 border-white w-16 mx-auto" />
          </div>
        </div>
      </div>


      <div className="w-[90%] md:w-5/6 absolute md:top-[300px] top-[250px] bottom-0 left-1/2 transform -translate-x-1/2 ">
        <figcaption className="items-center rounded-xl backdrop-saturate-200 backdrop-blur-2xl bg-opacity-80 border border-blue-gray-50/80 bg-[#fdfeff] p-6 shadow-lg shadow-black/5 saturate-100">
          <div  className="grid grid-cols-1 md:grid-cols-12  gap-4  ">
            <Card  className="flex col-span-12 md:flex-row shadow-none bg-transparent ">
              <CardHeader
                floated={false}
                className=" md:w-[50%] md:h-[100%] p-0 m-0  md:rounded-bl-xl md:rounded-tl-xl relative"
              >
                <img
                  src={AboutImg}
                  alt="card-image"
                  className="h-full w-full object-cover md:object-center md:rounded-none l  sm:h-full sm:w-full sm:rounded-md"
                />
              </CardHeader>
              <CardBody dir={t("dir")} className="w-full md:w-[70%]">
                <Typography variant="h6" color="gray" className="mb-4 uppercase">
                  {t("about.about")}
                </Typography>
                <Typography variant="h4" color="blue" className="mb-2">
                  {t("about.Company")}
                </Typography>
                <Typography color="gray" className="mb-8 font-normal">
                  {t("about.Company_desc")}
                </Typography>
                <Typography variant="h4" color="blue" className="mb-2">
                  {t("about.vision")}
                </Typography>
                <Typography color="gray" className="mb-8 font-normal">
                  {t("about.vision_desc")}
                </Typography>

                <Typography variant="h4" color="blue" className="mb-2">
                  {t("about.goals")}

                </Typography>
                <Typography color="gray" className="mb-8 font-normal">
                  {t("about.goals_desc1")}<br />
                  {t("about.goals_desc2")}<br />
                  {t("about.goals_desc3")}<br />
                  {t("about.goals_desc4")}<br />
                  {t("about.goals_desc5")}<br />
                </Typography>
                <Typography variant="h6" color="gray" className="mb-4 uppercase">
                  {t("about.committed")}
                </Typography>
              </CardBody>
            </Card>
          </div>
          <div dir={t("dir")} className=" my-20 space-y-4">
            <div className="flex flex-col items-center justify-center ">
              <div className="text-center ">
                <Typography variant="h4" color="blue" textGradient className="text-2xl">
                  {t("about.advantages")}
                </Typography>
              </div>
              <div className="border-blue-500 rounded-lg border-2 md:w-[25%] w-[90%] mt-5"></div>
            </div>
            <Features />
          </div>
          {/* Team */}
          <div dir={t("dir")} className=" my-20 space-y-4">
            <div className="flex flex-col items-center justify-center ">
              <div className="text-center ">
                <Typography variant="h4" color="blue" textGradient className="text-2xl">
                  {t("about.team")}
                </Typography>
              </div>
              <div className="border-blue-500 rounded-lg border-2 md:w-[25%] w-[90%] m-5"></div>
            </div>
            <Team />
          </div>
          {/* Team end */}
        </figcaption>
        <Footer />
      </div>
    </>
  )
}

export default About