import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Option,
  Select,
  Textarea,
} from "@material-tailwind/react";
import { Footer } from "../layouts/Footer";
import { users } from "./users";
import pdf from "./pdf.pdf";
import emailjs from "@emailjs/browser";
import { animateScroll as scroll } from "react-scroll";

const ProjectForm = () => {
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 50,
      smooth: "easeInOutQuint",
    });
  };

  const [projectType1, setProjectType1] = useState("");
  const [projectType2, setProjectType2] = useState("");
  const [projectType3, setProjectType3] = useState("");
  const addition1 = (users.find((e) => e.id == parseInt(projectType1)) || {})
    .addition;
  const addition2 = addition1
    ? (addition1.find((e) => e.id == parseInt(projectType2)) || {}).addition
    : null;

  const getPrice = (array, id) => {
    const item = array.find((e) => e.id === id);
    return item ? item.price : 0;
  };

  const getName = (array, id) => {
    const item = array.find((e) => e.id === id);
    return item ? item.name : "";
  };

  const price1 = getPrice(users, parseInt(projectType1));
  const price2 = addition1 ? getPrice(addition1, parseInt(projectType2)) : 0;
  const price3 = addition2 ? getPrice(addition2, parseInt(projectType3)) : 0;

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const price4 = selectedCheckboxes.reduce((accumulator, checkboxId) => {
    const checkbox = addition2
      ? addition2.find((cb) => cb.id === checkboxId)
      : null;
    return accumulator + (checkbox ? checkbox.price : 0);
  }, 0);

  const detail1 = getName(users, parseInt(projectType1));
  const detail2 = addition1 ? getName(addition1, parseInt(projectType2)) : "";
  const detail3 = addition2 ? getName(addition2, parseInt(projectType3)) : "";
  const detail4 = selectedCheckboxes.reduce((accumulator, checkboxId) => {
    const checkbox = addition2
      ? addition2.find((cb) => cb.id === checkboxId)
      : null;
    return accumulator + "/" + (checkbox ? checkbox.name : "");
  }, "");
  console.log(detail4);

  const handleCheckboxChange = (id) => {
    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      if (!prevSelectedCheckboxes.includes(id)) {
        return [...prevSelectedCheckboxes, id];
      }
      return prevSelectedCheckboxes.filter((selectedId) => selectedId !== id);
    });
  };

  const totalPrice =
    parseInt(projectType1) === 3
      ? price1 + price2 + price4
      : price1 + price2 + price3;

  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleCheckboxChange2 = () => {
    setTermsAccepted(!termsAccepted);
  };
  const [loading, setLoading] = useState("أرسال");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading("يرجا الأنتظار");
    const tempForm = document.createElement("form");
    tempForm.innerHTML = `
      <textarea name="user_name">${name}</textarea>
      <input type="email" name="user_email" value=${email} />
      <textarea name="user_users">${detail1}</textarea>
      <textarea name="user_addition">${detail2}</textarea>
      <textarea name="user_detail">${
        parseInt(projectType1) === 3 ? detail4 : detail3
      }</textarea>
      <input type="text" name="user_price" value=${totalPrice} />
      <textarea name="message">${message}</textarea>
    `;

    emailjs
      .sendForm(
        "service_5phrc0j",
        "template_vnvhr23",
        tempForm,
        "aoHGj9I2_xFeo9n2Q"
      )
      .then(
        (result) => {
          console.log(result.text);
          setTermsAccepted(!termsAccepted);
          setLoading("أرسال");
          tempForm.remove();
          setProjectType1("");
          setProjectType2("");
          setProjectType3("");
          setName("");
          setEmail("");
          setMessage("");
          scrollToTop();
        },
        (error) => {
          console.log(error.text);
          setLoading("أرسال");
          tempForm.remove();
        }
      );
  };

  return (
    <>
      <div className="flex justify-center items-center relative">
        <div className="w-full relative">
          <div className="md:h-[350px] h-[350px] w-full object-cover object-center bg-[#013356]"></div>
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
            <h2 className="text-white text-3xl md:text-4xl font-bold">
              المشاريع
            </h2>
            <hr className="mt-2 border-t-2 border-white w-16 mx-auto" />
          </div>
        </div>
      </div>

      <div className="w-[90%] md:w-5/6 absolute md:top-[300px] top-[250px] bottom-0 left-1/2 transform -translate-x-1/2">
        <figcaption className="items-center rounded-xl backdrop-saturate-200 backdrop-blur-2xl bg-opacity-80 border border-blue-gray-50/80 bg-[#fdfeff] p-6 shadow-lg shadow-black/5 saturate-100 overflow-hidden">
          <Typography variant="h4" color="blue-gray">
            تفاصيل المشروع
          </Typography>
          <Typography variant="h6" color="gray" className="mt-1 font-normal">
            اكتب تفاصيل المشروع الذي تريده
          </Typography>
          <form ref={form} onSubmit={sendEmail} className="mt-8 mb-2 ">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              <div className="mb-4">
                <Typography variant="h6" color="blue-gray" className="mb-1">
                  الأسم الكامل:
                </Typography>
                <Input
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                  name="user_name"
                  size="lg"
                  label="الأسم"
                />
              </div>
              <div className="mb-4">
                <Typography variant="h6" color="blue-gray" className="mb-1">
                  الأيميل:
                </Typography>
                <Input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  name="user_email"
                  size="lg"
                  label="الأيميل"
                />
              </div>
              <div className="mb-4">
                <Typography variant="h6" color="blue-gray" className="mb-1">
                  نوع المشروع:
                </Typography>
                <Select
                  name="user_users"
                  onChange={(value) => setProjectType1(value)}
                  label="أختر"
                >
                  {users.map((e) => {
                    return (
                      <Option key={e.id} value={`${e.id}`}>
                        {e.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              {addition1 ? (
                <div className="mb-4">
                  <Typography variant="h6" color="blue-gray" className="mb-1">
                    أضافة:
                  </Typography>
                  <Select
                    name="user_addition"
                    onChange={(value) => setProjectType2(value)}
                    label="أختر"
                  >
                    {addition1.map((e) => {
                      return (
                        <Option key={e.id} value={`${e.id}`}>
                          {e.name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              ) : null}
              {addition2 ? (
                parseInt(projectType1) == 3 ? (
                  <>
                    <Typography variant="h6" color="blue-gray" className="mb-1">
                      أضافة:
                    </Typography>
                    {addition2.map((e) => (
                      <Checkbox
                        name="user_detail"
                        checked={selectedCheckboxes.includes(e.id)}
                        onChange={() => handleCheckboxChange(e.id)}
                        key={e.id}
                        label={e.name}
                      />
                    ))}
                  </>
                ) : (
                  <div className="mb-4">
                    <Typography variant="h6" color="blue-gray" className="mb-1">
                      أضافة:
                    </Typography>
                    <Select
                      name="user_detail"
                      onChange={(value) => setProjectType3(value)}
                      label="أختر"
                    >
                      {addition2.map((e) => {
                        return (
                          <Option key={e.id} value={`${e.id}`}>
                            {e.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                )
              ) : null}
              <div className="mb-4">
                <Textarea
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  value={message}
                  name="message"
                  label="الملاحظة"
                />
              </div>
              <div className="mb-4">
                <Typography variant="h6" color="blue-gray" className="mb-1">
                  المبلغ:
                </Typography>
                <Input
                  name="user_price"
                  value={totalPrice + "$"}
                  label={totalPrice + "$"}
                  disabled
                />
              </div>
              <Checkbox
                checked={termsAccepted}
                onChange={handleCheckboxChange2}
                label={
                  <Typography color="blue-gray" className="flex font-medium">
                    قراءة وأوافق على
                    <Typography
                      as="a"
                      href={pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      color="blue"
                      className="font-medium transition-colors hover:text-blue-700"
                    >
                      &nbsp;الشروط والأحكام
                    </Typography>
                    .
                  </Typography>
                }
              />
              <div className="mb-4">
                <Button
                  type="submit"
                  disabled={!termsAccepted}
                  className="mt-6"
                  fullWidth
                >
                  {loading}
                </Button>
              </div>
            </div>
          </form>
        </figcaption>
        <Footer />
      </div>
    </>
  );
};

export default ProjectForm;
