
export const ar =  {
      dil:'اللغة',
      team:'فريقنا',
      home:'الرئيسية',
      pages:'الصفحات',
      contact:'اتصل بنا',
      about:'من نحن ',
      about_desc:'يمكنك النقر هنا لمعرفة المزيد عن شركتنا ورؤيتنا',
      products:'المنتجات',
      products_desc:'تعرّف على مجموعة منتجاتنا المتنوعة',
      programming:'البرمجة',
      programming_desc:'استفد من خدماتنا في مجال البرمجة',
      designs:'التصاميم',
      designs_desc:'استفد من خدمات التصميم المتنوعة التي نقدمها',

}
export const en =  {
      dil:'lang',
      team:'team',
      home:'الرئيسية',
      pages:'الصفحات',
      contact:'اتصل بنا',
      about:'من نحن ',
      about_desc:'يمكنك النقر هنا لمعرفة المزيد عن شركتنا ورؤيتنا',
      products:'المنتجات',
      products_desc:'تعرّف على مجموعة منتجاتنا المتنوعة',
      programming:'البرمجة',
      programming_desc:'استفد من خدماتنا في مجال البرمجة',
      designs:'التصاميم',
      designs_desc:'استفد من خدمات التصميم المتنوعة التي نقدمها',
}