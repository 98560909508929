import React from "react";
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
    Typography,
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { PiFileImageDuotone, PiMonitorPlayDuotone, PiPenNibDuotone } from "react-icons/pi";
import Video from "./Video";
import Post from "./Post";
import Logo from "./Logo";

export function DesignsTabs() {
    const [t] = useTranslation("global");

    const data = [
        {
            label: "video_tabs",
            value: " فيديوهات",
            icon: PiMonitorPlayDuotone,
            desc: <Video />,
        },
        {
            label: "post_tabs",
            value: " بوسترات",
            icon: PiFileImageDuotone,
            desc: <Post />,
        },
        {
            label: "logo_tabs",
            value: " لوجوات",
            icon: PiPenNibDuotone,
            desc: <Logo />,
        }
    ];
    return (
        <>
            <div className="flex flex-col items-center justify-center ">
                <div className="text-center ">
                    <Typography variant="h4" color="blue" textGradient className="text-2xl">
                        {t("designs.business")}
                    </Typography>
                    <Typography color="gray" className="mt-1 font-normal">
                        {t("designs.business_desc")}
                    </Typography>
                </div>
                <div className="border-blue-500 rounded-lg border-2 md:w-[25%]  w-[90%] m-5"></div>
            </div>
            <Tabs value=" فيديوهات">
                <TabsHeader>
                    {data.map(({ label, value, icon }) => (
                        <Tab key={value} value={value}>
                            <div className="flex items-center gap-2">
                                {React.createElement(icon, { className: "w-5 h-5" })}
                                {t(`designs.${label}`)}
                            </div>
                        </Tab>
                    ))}
                </TabsHeader>
                <TabsBody>
                    {data.map(({ value, desc }) => (
                        <TabPanel key={value} value={value}>
                            {desc}
                        </TabPanel>
                    ))}
                </TabsBody>
            </Tabs>
        </>
    );
}